@font-face {
  font-family: 'PPAgrandir';
  src: url('/fonts/PPAgrandir-Regular.woff2') format('woff2'),
    url('/fonts/PPAgrandir-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PPAgrandir';
  src: url('/fonts/PPAgrandir-Medium.woff2') format('woff2'),
    url('/fonts/PPAgrandir-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
